import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import loadable from "@loadable/component"

import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

const Layout = loadable(() => import("../components/layout"))
const SEO = loadable(() => import("../components/seo"))
const BanneronePage = loadable(() => import("../components/banneronePage"))
const TitoloPagina = loadable(() => import("../components/titoloPagina"))
const CardPagina = loadable(() => import("../components/cardPagina"))

const Ingegneria = () => (
  <Layout isPagina={true}>
    <SEO
      title="Soluzioni integrate di Ingegneria Energetica Lazio: impianti fotovoltaici, solari termici, termodinamici, consulenze energetiche, termografia a infrarossi"
      description="Azienda attiva nel settore degli impianti ad energie rinnovabili nel Lazio. La società è specializzata nella realizzazione di impianti fotovoltaici, eolici, solari termici e offre consulenza personalizzata"
    />
    <BanneronePage title="Ingegneria Energetica" />
    <Container className="mt-4 mb-4">
      <TitoloPagina title="Soluzioni integrate di Ingegneria Energetica Lazio: impianti fotovoltaici, solari termici, termodinamici, consulenze energetiche, termografia a infrarossi" />
      <Row>
        <Col className="h-100" md={8}>
          <CardPagina variante="bordoLeft">
            <p>
              <strong>Soluzioni Energetiche Integrate</strong>
              <br />
              La società NonsoloEnergia srl opera in tutte le province della
              regione Lazio, ma principalmente su Roma e Latina, in tutto
              il settore delle energie rinnovabili e risparmio energetico,
              reperendo le risorse finanziarie richieste da aziende e privati
              mediante finanziamenti ad hoc anche grazie a bandi regionali ed
              europei, eseguendo diagnosi energetiche, studi di fattibilità,
              progettazione degli interventi di
              riqualificazione/ristrutturazione energetica ed impianti ad
              energie rinnovabili (fotovoltaici, solari termici e
              termodinamici), realizzazione delle opere, manutenzione e
              monitoraggio degli impianti realizzati. Gli obiettivi di
              NonsoloEnergia srl per il settore del risparmio energetico ed
              energie rinnovabili nel Lazio possono essere riassunti in:
              <ul>
                <li>
                  fornire consulenza ad aziende e privati per individuare la
                  soluzione energetica più adatta alle proprie esigenze
                  sfruttando i vantaggi offerti in tutti i settori dall'adozione
                  di energie rinnovabili alle misure di risparmio energetico;
                </li>
                <li>
                  fornire direttamente e/o tramite una rete di collaboratori
                  tecnico-commerciali soluzioni complete basate su impianti
                  fotovoltaici, solari termici per la produzione di Acqua Calda Sanitaria o industriale, solari termodinamici,
                  cogenerazione ad elevato rendimento, interventi di
                  riqualificazione energetica degli edifici (climatizzazione a
                  basso consumo con sistemi idronici aria-acqua, isolamento
                  dell'involucro edilizio), a prezzi competitivi, pur mantenendo
                  un'elevata qualità delle soluzioni proposte.
                </li>
              </ul>
              In particolare i servizi offerti sono:
              <ol>
                <li>
                  AUDIT ED EFFICIENTAMENTO ENERGETICO
                  <ul>
                    <li>
                      <Link to="/termografia">
                        Check-up e Diagnosi energetica degli edifici con
                        Termografia ad Infrarossi
                      </Link>
                    </li>
                    <li>
                        Pianificazione Energetica
                    </li>
                    <li>
                      <Link to="/certificazione-energetica">
                        Certificazione energetica edifici (APE LAZIO)
                      </Link>
                    </li>
                    <li>
                      Simulazione computerizzata di ciascun intervento ai fini
                      dell'ottimizzazione dei benefici energetici ed economici
                    </li>
                    <li>Realizzazione del business plan aziendale</li>
                  </ul>
                </li>
                <li className="mt-2">
                  REALIZZAZIONI AZIENDALI E RESIDENZIALI DI:
                  <ul>
                    <li>
                      <Link to="/fotovoltaico-residenziale">
                        Impianti Fotovoltaici Residenziali
                      </Link>
                    </li>
                    <li>
                      <Link to="/fotovoltaico-con-accumulo">
                        Impianti Fotovoltaici con Accumulo
                      </Link>
                    </li>
                    <li>
                      <Link to="/fotovoltaico-aziendale">
                        Impianti Fotovoltaici Aziendali
                      </Link>
                    </li>
                    <li>
                      <Link to="/eternit-e-fotovoltaico">
                        Interventi di smaltimento Eternit/Amianto e coperture
                        fotovoltaiche
                      </Link>
                    </li>
                    <li>
                      <Link to="/cogenerazione">
                        Impianti a Micro Cogenerazione ad alto rendimento
                      </Link>
                    </li>
                    <li>
                      <Link to="/solare-termodinamico">
                        Impianti Solari Termodinamici
                      </Link>
                    </li>
                    <li>
                      Riqualificazione energetica edifici (DETRAZIONE IRPEF/IRES
                      65%):
                      <ul>
                        <li>isolamento termico pareti con cappotti termici</li>
                        <li>rifacimento/isolamento coperture</li>
                        <li>
                          impianti idronici aria-acqua per riscaldamento e
                          climatizzazione a pompa di calore
                        </li>
                        <li>
                          impianti idrotermici ad acqua di falda acqua-acqua per
                          riscaldamento e climatizzazione
                        </li>
                        <li>
                          impianti per produzione di acqua calda sanitaria
                        </li>
                        <li>impianti solari termici ad alta efficienza</li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/termografia">
                        Interventi di manutenzione edifici/impianti con analisi
                        Termografica
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="mt-2">
                  ITER BUROCRATICI PER LE AGEVOLAZIONI e FINANZIAMENTI AD HOC
                  per privati ed aziende
                  <ul>
                    <li>
                      Agevolazioni regionali e nazionali (Detrazione 50% (IRPEF)
                      e 65%IRPEF e IRES))
                    </li>
                    <li>
                      Bandi regionali, nazionali ed europei per le imprese
                    </li>
                    <li>
                      Finanziamenti 100% non ipotecari per realizzazione
                      impianti/interventi
                    </li>
                  </ul>
                </li>
              </ol>
            </p>
          </CardPagina>
        </Col>
        <Col className="text-center mx-auto mt-3" md={4}>
          <img
            className="img-fluid w-70 mx-auto"
            src="../ingegneria/cappotto-termico-roma.jpg"
            alt="cappotto termico roma Anzio"
          />
          <img
            className="img-fluid w-70 mx-auto mt-3"
            src="../ingegneria/come-risparmiare-energia-Roma.jpg"
            alt="Impianti Fotovoltaici Anzio"
          />

          <img
            className="img-fluid mx-auto w-70 mt-3"
            src="../ingegneria/fotovoltaico-roma.jpg"
            alt="Impianti solari e fotovoltaici Lazio"
            title="Impianti solari, fotovoltaici, certificazione energetica Lazio"
          />
          <img
            className="img-fluid w-70 mx-auto d-block mt-3"
            src="../ingegneria/impianto-solare-termodinamico.jpg"
            alt="Impianto Solare Termodinamico"
          />
          <img
            className="img-fluid w-70 mt-3 mx-auto"
            src="../ingegneria/isolamento_termico_coperture-lazio.jpg"
            alt="isolamento termico coperture lazio"
          />
          <img
            className="img-fluid w-70 mt-3 mx-auto"
            src="../ingegneria/pannello-fotovoltaico-lazio.jpg"
            alt="Pannello Fotovoltaico lazio"
          />
          <img
            className="img-fluid w-70  mt-3 mx-auto"
            src="../ingegneria/rimozione-eternit.jpg"
            alt="Rimozione Eternit Anzio"
          />
          <img
            className="img-fluid w-70 mt-3 mx-auto"
            src="../ingegneria/roma-film.jpg"
          />
          <img
            className="img-fluid w-70  mt-3 mx-auto"
            src="../ingegneria/termografia-per-muffa.jpg"
            alt="Termografia per Muffa Nettuno"
          />
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default Ingegneria
